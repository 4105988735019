import { all, takeLatest, put } from 'redux-saga/effects'
import homeSaga from './home/master'
import bannerSaga from './home/banner'

import masterSaga from './master/master'
import moreSaga from './more/common'
import userSaga from './user'
import mySaga from './my/my'
import {logout} from "../../../constants/funtions";

const taker = (actionType, func, takeMethod = takeLatest, takeOption) => {
  const params = [
    actionType,
    function* (action) {
      try {
        const result = yield func(action);

        if (action.type === 'MASTER_PROFILE_INIT'
          || action.type === 'HOME_RESET'
          || action.type === 'HOME_MASTER_LIST_INIT'
          || action.type === 'MASTER_REVIEWS_INIT'
          || action.type === 'MY_ADD_REVIEW_INIT') {
          yield put({
            type: `${actionType}__success`,
            data: {},
          });
          return;
        }

        if (!result || !result.data) throw new Error('no data', actionType);
        if (result.data.result_code !== 200) throw (result.data);

        yield put({
          type: `${actionType}__success`,
          data: {...result.data, action : action.data},
        })

      } catch (err) {
        if (err.response && err.response.status === 401) {
          logout();
          window.location.href = '/more?tokenExpired=true';
        }
        yield put({
          type: `${actionType}__failure`,
          error: err,
        })
      }
    },
  ];
  if (takeOption) params.unshift(takeOption);

  return takeMethod(...params)
};

const takesAll = sagaItems => {
  return all(sagaItems.map(saga => taker(...saga)))
};

export default function* rootSaga() {
  yield all([
    takesAll(homeSaga),
    takesAll(bannerSaga),
    takesAll(masterSaga),
    takesAll(moreSaga),
    takesAll(userSaga),
    takesAll(mySaga),
  ])
}