import { combineReducers } from 'redux'
import { produce } from 'immer'
import userReducerMap, { initialState as userInitialState } from './user'
import homeReducerMap, { initialState as homeInitialState } from './home/master'
import bannerReducerMap, { initialState as bannerInitialState } from './home/banner'

import masterReducerMap, { initialState as masterInitialState } from './master/master'
import moreReducerMap, { initialState as moreInitialState } from './more/common'
import myReducerMap, { initialState as myInitialState } from './my/my'

import {FAILURE, REQUEST} from "../actions";

const reduceFunc = (reducerMap, initialState) =>
  produce((draft = initialState, action) => {
    const { error, type } = action;
    const [actionType, status = REQUEST] = type.split('__');
    const source = reducerMap[actionType];
    if (!source) return draft;
    if (typeof source === 'function') return source(draft, action);
    draft.actionStatus[actionType] = status === FAILURE ? error : status;
    source[status] && source[status](draft, action)
  });

const rootReducer = combineReducers({
  user: reduceFunc(userReducerMap, userInitialState),
  home: reduceFunc(homeReducerMap, homeInitialState),
  banners: reduceFunc(bannerReducerMap, bannerInitialState),
  master: reduceFunc(masterReducerMap, masterInitialState),
  more: reduceFunc(moreReducerMap, moreInitialState),
  my: reduceFunc(myReducerMap, myInitialState),
});

export default rootReducer