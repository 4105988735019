import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from '../App';
import { ReactChannelIO } from 'react-channel-plugin';
import { CHANNEL_TALK_API_KEY } from '../constants/constants';

import { Provider } from "react-redux";
import rootSaga from "../modules/redux/saga";
import { store, persistor, sagaMiddleware } from '../modules/redux/store';
import { PersistGate } from 'redux-persist/integration/react';

sagaMiddleware.run(rootSaga);

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <ReactChannelIO pluginKey={CHANNEL_TALK_API_KEY} language='ko' hideChannelButtonOnBoot={true} autoBoot>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    </ReactChannelIO>
    </PersistGate>
  </Provider>
);

export default Root;