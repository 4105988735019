import Actions from '../../actions'
import {
  getMyClHistory,
  getMyReview,
  getMyWritableReview,
  getMyWritableReviewStatus
} from "../../../../services/apis/my";
import {addReview, editReview} from "../../../../services/apis/reviews";
import {getCoinHistories} from "../../../../services/apis/payments";

const myClHistory = function*(action) {
  return yield getMyClHistory(action.data);
};

const myReview = function*(action) {
  return yield getMyReview(action.data);
};

const myCoinHistory = function*(action) {
  return yield getCoinHistories(action.data);
};

const myWritableReview = function*(action) {
  return yield getMyWritableReview(action.data);
};

const myWritableReviewStatus = function*(action) {
  return yield getMyWritableReviewStatus(action.data);
};

const writeReview = function*(action) {
  return yield addReview(action.data);
};

const modifyReview = function*(action) {
  return yield editReview(action.data);
};

const initReview = function*(action) {
  return {result_code:0}
};

export default [
  [Actions.MY_COUNSEL, myClHistory],
  [Actions.MY_REVIEW, myReview],
  [Actions.MY_COIN_HISTORY, myCoinHistory],
  [Actions.MY_WRITABLE_REVIEW, myWritableReview],
  [Actions.MY_WRITABLE_REVIEW_STATUS, myWritableReviewStatus],
  [Actions.MY_ADD_REVIEW, writeReview],
  [Actions.MY_MODIFY_REVIEW, modifyReview],
  [Actions.MY_ADD_REVIEW_INIT, initReview],
]