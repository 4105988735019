import { makeGetRequest, makePostRequest, makePutRequest } from '../api';

export function getUsers() {
  return makeGetRequest({
    url: `/api/member/info`
  });
}

export function login(data) {
  return makePostRequest({
    url: `/api/member/login`,
    data: data,
  });
}

export function signUp(data) {
  return makePostRequest({
    url: `/api/member/signUp`,
    data: data,
  });
}

export function getAppInfo(type='version') {
  return makeGetRequest({
    url: `/api/common/app/info/${type}`,
    // params: query
  });
}

export function changePassword(data) {
  return makePutRequest({
    url: `/api/member/pwd`,
    data: data,
  });
}

export function changeMobile(data) {
  return makePutRequest({
    url: `/api/member/phone`,
    data: data,
  });
}

export function memberOut(data) {
  return makePutRequest({
    url: `/api/member/out`,
    data: data,
  });
}

export function resetPassword(uu_email) {
  return makePostRequest({
    url: `/api/member/re/passwd`,
    data: {
      uu_email: uu_email
    },
  });
}

export function recoverEmail(uu_phone) {
  return makePostRequest({
    url: `/api/member/auth/number`,
    data: {
      uu_phone: uu_phone
    },
  });
}

export function quickCall(csidx) {
  return makePutRequest({
    url: `/api/call/quick/${csidx}`,
  });
}

export function addPush(data) {
  return makePutRequest({
    url: `/api/device/alarm`,
    data: data
  });
}

export function myPushInfo(data) {
  return makePostRequest({
    url: `/api/device/my/info`,
    data: data
  });
}

export function addDevice(data) {
  return makePutRequest({
    url: `/api/device/info`,
    data: data
  });
}