import { makeGetRequest, makePostRequest } from '../api';

export function getFaq() {
  return makeGetRequest({
    url: `/api/common/faq/list/all`
  });
}

export function revokeAppleToken(data) {
  return makePostRequest({
    url: `/api/common/apple/token`,
    params: data
  });
}