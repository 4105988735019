import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import {
  APPSTORE,
  PLAYSTORE,
  REGIST_TARO_MASTER_HREF,
} from './constants/constants';

import './common/css/element.css';
import './common/css/layout.css';
import './common/css/reset.css';

const loading = () => <div className="animated fadeIn pt-3 text-center"> </div>;

const DefaultLayout = React.lazy(() =>
  import('./views/components/DefaultLayout')
);

//#### Hide Tab Menu Pages
const Login = React.lazy(() => import('./views/pages/Login'));
const LoginFindEmail = React.lazy(() =>
  import('./views/pages/Login/FindEmail')
);
const LoginConfirmEmail = React.lazy(() =>
  import('./views/pages/Login/ConfirmEmail')
);
const LoginResetPassword = React.lazy(() =>
  import('./views/pages/Login/ResetPassword')
);

//### Others
const Page404 = React.lazy(() => import('./views/pages/Page404'));
const Page500 = React.lazy(() => import('./views/pages/Page500'));

//### Reviews
const ReviewPolicyPopup = React.lazy(() =>
  import('./views/pages/Popup/Review/ReviewPolicy')
);
const ReviewStep1 = React.lazy(() =>
  import('./views/pages/Popup/Review/ReviewStep1')
);
const ReviewStep2 = React.lazy(() =>
  import('./views/pages/Popup/Review/ReviewStep2')
);
const WritableReview = React.lazy(() =>
  import('./views/pages/Popup/Review/WritableReview')
);

//#### More Popup
const PushSettingsPopup = React.lazy(() =>
  import('./views/pages/Popup/More/MorePushSettings')
);
const AppInfoPopup = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAppInfoPopup')
);
const ServicePolicyPopup = React.lazy(() =>
  import('./views/pages/Popup/More/MoreServicePolicyPopup')
);
const FaqPopup = React.lazy(() =>
  import('./views/pages/Popup/More/MoreFaqPopup')
);
const NoticePopup = React.lazy(() =>
  import('./views/pages/Popup/More/MoreNoticePopup')
);

//### Accounts
const AccountSettings = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountSettings')
);
const AccountSettingsMobile = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountMobile')
);
const AccountSettingsMobileChange = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountMobileChange')
);
const AccountSettingsMobileKcp = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountMobileChangeKcp')
);
const AccountSettingsPassword = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountPassword')
);
const AccountSettingsLeave = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountLeave')
);
const AccountSettingsLeavePassword = React.lazy(() =>
  import('./views/pages/Popup/More/MoreAccountLeavePassword')
);

//### SignUp
const SignupCallbackNaver = React.lazy(() =>
  import('./views/pages/Register/CallbackNaver')
);
const SignupCallbackApple = React.lazy(() =>
  import('./views/pages/Register/CallbackApple')
);
const SignupEmail = React.lazy(() => import('./views/pages/Register'));
const SignupPassword = React.lazy(() =>
  import('./views/pages/Register/Password')
);
const SignupMobile = React.lazy(() => import('./views/pages/Register/Mobile'));
const SignupMobileKcp = React.lazy(() =>
  import('./views/pages/Register/MobileKcp')
);
const SignupComplete = React.lazy(() =>
  import('./views/pages/Register/Complete')
);

//### Coin Shop
const ChargePopup = React.lazy(() => import('./views/pages/Popup/Shop/Charge'));
const ChargePaymentPopup = React.lazy(() =>
  import('./views/pages/Popup/Shop/PaymentKcp')
);
const ChargeHistoryPopup = React.lazy(() =>
  import('./views/pages/Popup/Shop/History')
);
const ChargeInfoPopup = React.lazy(() =>
  import('./views/pages/Popup/Shop/CoinInfo')
);

//### Master Page
const HomeMastersContainer = React.lazy(() =>
  import('./views/Home/MastersContainer')
);
const MastersContainer = React.lazy(() =>
  import('./views/Master/MasterContainer')
);
const CoinCharge = React.lazy(() => import('./views/Master/CoinCharge'));
const VideoPlayerPopup = React.lazy(() =>
  import('./views/pages/Popup/Master/VideoPlayer')
);

//### Login Modal
const LoginModal = React.lazy(() =>
  import('./views/components/LoginModal/LoginModal')
);

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const closeModal = (showModal) => {
    setShowLoginModal(!showModal);
  };

  const openLoginModal = (showModal) => {
    setShowLoginModal(showModal);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      if (
        !window.webkit &&
        !window.bridgeTS &&
        window.location.search.includes("payment=") &&
        window.location.origin.includes("web.tarotsalon.com")
      ) {
        window.location.href = `https://tarotsalon.com${window.location.pathname}${window.location.search}`;
      }
    }
  }, []);

  return (
    <>
      <div className="fixed root-layout-bg" />
      <div className="fixed root-layout-bg-image" />

      <React.Suspense fallback={loading()}>
        <LoginModal showPopup={showLoginModal} toggleModal={closeModal} />
        <ScrollToTop />
        <div className="root-layout">
          <div className="root-layout-inner left">
            <div className="fixed-wrapper">
              <div className="box">
                <p>나를 알아주는 타로상담</p>
                <img src={'/bi.png'} className="bi" />

                <div className="store-wrapper">
                  <a href={APPSTORE}>
                    <img src={'/appstore.png'} className="store" />
                  </a>
                  <a href={PLAYSTORE}>
                    <img src={'/playstore.png'} className="store" />
                  </a>
                </div>
              </div>
              <div className="footer">
                <p>
                  타로마스터로 등록할 수 있습니다. 지금 상담을 시작하세요.
                  <br />
                  타로마스터 등록 더 많은 고객들의 고민을 상담해 줄
                  타로마스터라면,
                  <br />
                  망설이지 마세요.
                </p>
                <a href={REGIST_TARO_MASTER_HREF}>
                  <button>타로마스터 등록</button>
                </a>
              </div>
            </div>
          </div>
          <div className="root-layout-inner right">
            <Switch>
              <Route
                key="moreAccountSettings"
                exact
                path="/more/account/settings"
                name="More Account Setting"
                render={(props) => <AccountSettings {...props} />}
              />
              <Route
                key="moreAccountSettingsMobile"
                exact
                path="/more/account/settings/mobile"
                name="More Account Setting Mobile"
                render={(props) => <AccountSettingsMobile {...props} />}
              />
              <Route
                key="moreAccountSettingsMobileChange"
                exact
                path="/more/account/settings/mobile/change"
                name="More Account Setting Mobile change"
                render={(props) => <AccountSettingsMobileChange {...props} />}
              />
              <Route
                key="moreAccountSettingsMobileChangeKcp"
                exact
                path="/more/account/settings/mobile/kcp"
                name="More Account Setting Mobile kcp"
                render={(props) => <AccountSettingsMobileKcp {...props} />}
              />
              <Route
                key="moreAccountSettingsPassword"
                exact
                path="/more/account/settings/password"
                name="More Account Setting Password"
                render={(props) => <AccountSettingsPassword {...props} />}
              />
              <Route
                key="moreAccountSettingsLeave"
                exact
                path="/more/account/settings/leave"
                name="More Account Setting Leave"
                render={(props) => <AccountSettingsLeave {...props} />}
              />
              <Route
                key="moreAccountSettingsLeavePassword"
                exact
                path="/more/account/settings/leave/password"
                name="More Account Setting Leave Password"
                render={(props) => <AccountSettingsLeavePassword {...props} />}
              />

              <Route
                key="login"
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                key="findEmail"
                exact
                path="/login/find/email"
                name="Login Find Email Page"
                render={(props) => <LoginFindEmail {...props} />}
              />
              <Route
                key="confirmEmail"
                exact
                path="/login/find/email/confirm"
                name="Login Find Email Confirm Page"
                render={(props) => <LoginConfirmEmail {...props} />}
              />

              <Route
                key="reset password"
                exact
                path="/login/reset/password"
                name="Login Reset Password Page"
                render={(props) => <LoginResetPassword {...props} />}
              />

              <Route
                key="signUpEmail"
                exact
                path="/register"
                name="Register Page"
                render={(props) => <SignupEmail {...props} />}
              />
              <Route
                key="signUpPassword"
                exact
                path="/register/password"
                name="Register Password Page"
                render={(props) => <SignupPassword {...props} />}
              />
              <Route
                key="signUpMobile"
                exact
                path="/register/mobile"
                name="Register Page"
                render={(props) => <SignupMobile {...props} />}
              />
              <Route
                key="signUpMobileKcp"
                exact
                path="/register/mobile/kcp"
                name="Register Page Mobile Kcp"
                render={(props) => <SignupMobileKcp {...props} />}
              />
              <Route
                key="signUpComplete"
                exact
                path="/register/complete"
                name="Register Password Page"
                render={(props) => <SignupComplete {...props} />}
              />
              <Route
                key="signUpCallbackNaver"
                exact
                path="/callback/naver"
                name="Register Page Callback Naver"
                render={(props) => <SignupCallbackNaver {...props} />}
              />
              <Route
                key="signUpCallbackApple"
                exact
                path="/callback/apple"
                name="Register Page Callback Apple"
                render={(props) => <SignupCallbackApple {...props} />}
              />

              <Route
                key="404"
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                key="500"
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />

              <Route
                key="reviewPolicy"
                exact
                path="/popup/review/policy"
                name="Review policy popup"
                render={(props) => <ReviewPolicyPopup {...props} />}
              />
              <Route
                key="reviewStep1"
                exact
                path="/popup/review/step1"
                name="Review policy step1"
                render={(props) => <ReviewStep1 {...props} />}
              />
              <Route
                key="reviewStep2"
                exact
                path="/popup/review/step2"
                name="Review policy step2"
                render={(props) => <ReviewStep2 {...props} />}
              />
              <Route
                key="reviewWritable"
                exact
                path="/popup/review/writable"
                name="Review policy step2"
                render={(props) => <WritableReview {...props} />}
              />

              <Route
                key="morePushSettings"
                exact
                path="/popup/push"
                name="Push settings popup"
                render={(props) => <PushSettingsPopup {...props} />}
              />
              <Route
                key="moreAppInfo"
                exact
                path="/popup/appInfo"
                name="App Info popup"
                render={(props) => <AppInfoPopup {...props} />}
              />

              <Route
                key="moreServiceTerm"
                exact
                path="/popup/service/term"
                name="Service policy term popup"
                render={(props) => (
                  <ServicePolicyPopup {...props} type="APP_TERM" />
                )}
              />
              <Route
                key="moreServicePrivacy"
                exact
                path="/popup/service/privacy"
                name="Service policy privacy popup"
                render={(props) => (
                  <ServicePolicyPopup {...props} type="APP_PRIVACY" />
                )}
              />
              <Route
                key="moreServiceCancel"
                exact
                path="/popup/service/cancel"
                name="Service policy cancel popup"
                render={(props) => (
                  <ServicePolicyPopup {...props} type="APP_CANCEL" />
                )}
              />

              <Route
                key="moreFaqList"
                exact
                path="/popup/faq"
                name="Faq List popup"
                render={(props) => <FaqPopup {...props} />}
              />
              <Route
                key="moreNoticeList"
                exact
                path="/popup/notice"
                name="Notice list popup"
                render={(props) => <NoticePopup {...props} />}
              />

              <Route
                key="materDetailVideo"
                exact
                path="/master/video"
                name="Video Player popup"
                render={(props) => <VideoPlayerPopup {...props} />}
              />
              <Route
                key="homeMasters"
                exact
                path="/home/masters"
                name="Home Masters"
                render={(props) => <HomeMastersContainer {...props} />}
              />

              <Route
                key="shopCoinCharge"
                exact
                path="/shop/charge"
                name="Shop Charge popup"
                render={(props) => <ChargePopup {...props} />}
              />
              <Route
                key="shopCoinChargePayment"
                exact
                path="/shop/charge/payment"
                name="Shop Charge payment popup"
                render={(props) => <ChargePaymentPopup {...props} />}
              />

              <Route
                key="shopCoinChargeHistory"
                exact
                path="/shop/history"
                name="Shop Charge history popup"
                render={(props) => <ChargeHistoryPopup {...props} />}
              />
              <Route
                key="shopCoinChargeCoinInfo"
                exact
                path="/shop/coininfo"
                name="Shop Charge info popup"
                render={(props) => <ChargeInfoPopup {...props} />}
              />

              <Route
                key="master"
                exact
                path="/master"
                name="Home Masters"
                render={(props) => (
                  <MastersContainer {...props} showModal={openLoginModal} />
                )}
              />
              <Route
                key="masterCoinCharge"
                exact
                path="/master/coin"
                name="Home Masters Coin Charge"
                render={(props) => <CoinCharge {...props} />}
              />

              <Route
                key="defaultLayout"
                path="/"
                name="Home"
                render={(props) => (
                  <DefaultLayout {...props} showModal={openLoginModal} />
                )}
              />
            </Switch>
          </div>
        </div>
      </React.Suspense>
    </>
  );
};

export default App;
