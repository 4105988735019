import { makeGetRequest, makePostRequest } from '../api';

export function getPayments() {
  return makeGetRequest({
    url: `/api/payment/prod/list`
  });
}

export function getCoinHistories() {
  return makeGetRequest({
    url: `/api/my/coin/used/list`
  });
}

export function getCoinDetail(slidx) {
  return makeGetRequest({
    url: `/api/my/coin/info/${slidx}`
  });
}

export function regOrder(data) {
  return makePostRequest({
    url: `/api/payment/order`,
    data: data,
  });
}

export function skipKcpPay(data) {
  return makePostRequest({
    url: `/api/payment/order/direct/complete`,
    data: data,
  });
}