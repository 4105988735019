import Actions from '../actions'
import * as user from "../../../services/apis/users";


const login = function*(action) {
  return yield user.login(action.data)
};

const login_user = function*(action) {
  return yield user.getUsers(action.data)
};

const logout = function*(action) {
  return {data : {result_code:200, logout: true}};
};

const signUp = function*(action) {
  return yield user.signUp(action.data);
};

export default [
  [Actions.LOGIN, login],
  [Actions.LOGOUT, logout],
  [Actions.LOGIN_USER, login_user],
  [Actions.SIGN_UP, signUp]

]