import Actions, {SUCCESS} from '../../actions';

export const initialState = {
  banners: [],
  tags: [],
  actionStatus: {},
};

export default {
  [Actions.HOME_BANNER]: {
    [SUCCESS]: (draft, { data }) => {
      draft.banners = data.result_data.list;
    },
  },
  [Actions.HOME_TAGS]: {
    [SUCCESS]: (draft, { data }) => {
      draft.tags = data.result_data.list;
    },
  },
}