export const CS_TYPE_POPULARS = 'popular';
export const CS_TYPE_NEW = 'new';
export const CS_TYPE_RECOMMEND = 'recommend';

export const KAKAO_APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY;
export const NAVER_APP_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const CHANNEL_TALK_API_KEY = process.env.REACT_APP_CHANNEL_TALK_API_KEY;
export const APPLE_CLIENT_ID = 'com.tarotsalon.web';

export const NAVER_LOGIN_CALLBACK = '/callback/naver';
export const APPLE_LOGIN_CALLBACK = '/callback/apple';

export const API_KCP = process.env.REACT_APP_KCP_API_URL + '/v1/';
export const IMG_URL = 'https://img.tarotsalon.com/cs/profile';

export const SHARE_URL =
  'https://tarotsalon2.page.link/?link=' + process.env.REACT_APP_SHARE_URL;
export const SHARE_URL_WITH_PARAMS =
  '&apn=kr.co.utosoft.tarotsalon&ibi=com.wincomi.ios.lotteryapp&isi=1512039611'; //isi=931324602

export const CS_TAG_SINGLE_AE_JEONG = '5'; // 애정운
export const CS_TAG_SINGLE_YEON_AE = '6'; // 연애심리
export const CS_TAG_SINGLE_JIK_JANG = '11'; // 직장운
export const CS_TAG_SINGLE_CHI_UP = '12'; // 취업운
export const CS_TAG_SINGLE_SA_UP = '13'; // 사업운
export const CS_TAG_SINGLE_GOONG_HAB = '9'; // 궁합
export const CS_TAG_SINGLE_SUM = '7'; // 썸

export const CS_SORT_BY_CL = 'cl'; // 상담 많은 순
export const CS_SORT_BY_REVIEW = 'review'; // 리뷰 많은 순
export const CS_SORT_BY_RATING = 'rating'; // 별점 높은 순
export const CS_SORT_BY_NEW = 'new'; // 최신순

export const BANNER_TYPE_MAIN = 'main';
export const BANNER_TYPE_COIN = 'coin';

export const BANNER_EVENT = [
  { link: 'https://flink.tarotsalon.com/signup', path: '/register' },
  {
    link: 'https://flink.tarotsalon.com/myreview',
    path: '/my',
    search: new URLSearchParams({ tab: 3 }).toString(),
  },
  { link: 'https://flink.tarotsalon.com/charge', path: '/shop' },
  { link: 'https://flink.tarotsalon.com/counselor', path: '/master' },
];

export const REVIEW_SORT_BY_NEW = 'new'; // 리뷰 최신순
export const REVIEW_SORT_BY_HIGH = 'high'; // 별점 높은순
export const REVIEW_SORT_BY_LOW = 'low'; // 별점 낮은순

export const JOIN_TYPE_EMAIL = '01';
export const JOIN_TYPE_KAKAO = '02';
export const JOIN_TYPE_NAVER = '03';
export const JOIN_TYPE_FACEBOOK = '04';
export const JOIN_TYPE_APPLE = '05';
export const APPSTORE =
  'https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EC%82%B4%EB%A1%B1/id931324602';
export const PLAYSTORE =
  'https://play.google.com/store/apps/details?id=kr.co.utosoft.tarotsalon&hl=ko&gl=US&pli=1';

export const STORAGE_KEY = {
  PURCHASE: 'PURCHASE',
};

export const REGIST_TARO_MASTER_HREF = '/landing/apply.html';
