import { makeGetRequest } from '../api';
import {getQueryUuidx} from "../../constants/funtions";
import _ from "lodash";

export function getTags() {
  return makeGetRequest({
    url: `/api/common/tag/list`
  });
}

export function getTagsByTagId(tgidx, sort) {
  return makeGetRequest({
    url: `/api/cs/tag/list/${tgidx}/${sort}`,
    params: _.pickBy(getQueryUuidx())
  });
}