import {makeGetRequest, makePutRequest, makeDeleteRequest} from '../api';

export function getMyClHistory(query) {
  return makeGetRequest({
    url: `/api/my/cl/history/list`,
    params: query
  });
}

export function getMyReview(query) {
  return makeGetRequest({
    url: `/api/my/review/list`,
    params: query
  });
}

export function getMyWritableReview(query) {
  return makeGetRequest({
    url: `/api/my/writable/review/list`,
    params: query
  });
}

export function getMyWritableReviewStatus(query) {
  return makeGetRequest({
    url: `/api/review/writable`,
    params: query
  });
}

export function getMyRecently(query) {
  return makeGetRequest({
    url: `/api/my/recently/list`,
    params: query
  });
}

export function getMyBookmark(order='new') {
  return makeGetRequest({
    url: `/api/my/bookmark/list/${order}`,
  });
}

export function addBookmark(csidx) {
  return makePutRequest({
    url: `/api/cs/add/bookmark/${csidx}`,
  });
}

export function addRecently(csidx) {
  return makePutRequest({
    url: `/api/cs/add/recently/${csidx}`,
  });
}

export function deleteBookmark(csidx) {
  return makeDeleteRequest({
    url: `/api/cs/delete/bookmark/${csidx}`,
  });
}

export function deleteRecently(csidx) {
  return makeDeleteRequest({
    url: `/api/cs/delete/recently/${csidx}`,
  });
}