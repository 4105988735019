import axios from 'axios';
import {getUserKey, isLogin} from "../constants/funtions";

const BASE_URL = process.env.REACT_APP_API_URL; //By Pass Proxy. For Web App

require('axios-debug-log')({
  request: function (debug, config) {
    debug('Request with ' + config.headers);
    debug('Request with ' + config)
  },
  response: function (debug, response) {
    debug(
      'Response with ' + response.headers,
      'from ' + response.config.url
    )
  },
  error: function (debug, error) {
    // Read https://www.npmjs.com/package/axios#handling-errors for more info
    debug('Boom', error)
  }
});

function requestProcess(request) {
  if (request.url && request.url.indexOf("http") === -1 && process.env.NODE_ENV !== 'development') {
    request.url = BASE_URL + request.url;
  }

  /*axios.defaults.headers.common['tarotsalon-api-key'] = TOKEN;*/

  if (isLogin()) {
    axios.defaults.headers['Authorization'] = 'Bearer ' + getUserKey();
  } else {
    axios.defaults.headers['Authorization'] = '';
  }


  request.withCredentials = false;
  return request;
}

axios.interceptors.response.use(undefined, function (err) {
  // return new Promise(function (resolve, reject) {
  //   if (err && err.response && err.response.status && err.response.status === 401) {
  //     // console.log("#401_Unauthorized");
  //     window.location.href = process.env.LOGIN_URL;
  //   } else if (err && err.response && err.response.status && err.response.status === 403 && err.response.message) {
  //     if (err.response.error === 'access_denied') {
  //       // window.location.href = process.env.ERROR_URL
  //       store.dispatch(addToast({
  //         text: 'Access denied'
  //       }))
  //       reject(err);
  //     } else {
  //       store.dispatch(addToast({
  //         text: err.response.message
  //       }))
  //       reject(err);
  //     }
  //   } else if (err.response.status === 500) {
  //     store.dispatch(addToast({
  //       text: err.response.message ? err.response.message : "Something wrong with connection"
  //     }))
  //     reject(err);
  //   } else {
  //     reject(err);
  //   }
  // });
  if (err.response) {
    if (err.response.status && err.response.status === 401) {
      console.log("#401_Unauthorized");

      //localStorage.setItem('logined', false);
      //window.location.href = '/adminui/login';

      return Promise.reject(err.response);
    } else if (err.response.status && err.response.status === 403) {
      console.log('Access to the specified resource has been forbidden');
      /*store.dispatch(addToast({
        text: 'Access to the specified resource has been forbidden'
      }))*/
      return Promise.reject(err.response);
    } else if (err.response.status && err.response.status === 404) {
      console.log('The requested resource is not available.');
      /*store.dispatch(addToast({
        text: "The requested resource is not available."
      }))*/
      return Promise.reject(err.response);
    } else {
      return Promise.reject(err.response);
    }
  } else {
    return Promise.reject(err);
  }
  // })
});

export function makeGetRequest(request) {
  return axios(requestProcess({...request, 'method': 'get'}));
}

export function makePostRequest(request) {
  return axios(requestProcess({...request, 'method': 'post'}));
}

export function makePutRequest(request) {
  return axios(requestProcess({...request, 'method': 'put'}));
}

export function makeDeleteRequest(request) {
  return axios(requestProcess({...request, 'method': 'delete'}));
}

export function makePatchRequest(request) {
  return axios(requestProcess({...request, 'method': 'patch'}));
}
