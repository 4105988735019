import Actions, {SUCCESS} from '../actions';
import {saveUserKey, saveUserProfile} from "../../../constants/funtions";

export const initialState = {
  user_Id: null,
  is_login: false,
  user: {},
  actionStatus: {},
  token:null
};

export default {
  [Actions.LOGIN]: {
    [SUCCESS]: (draft, { data }) => {
      saveUserKey(data.result_data.token);
      draft.is_login = true;
      draft.token = data.result_data.token;
    },
  },
  [Actions.LOGOUT]: {
    [SUCCESS]: (draft, { data }) => {
      draft.is_login = false;
      draft.token = null;
      draft.user = null;
      draft.actionStatus = {};
      draft.user_id = null;
    },
  },
  [Actions.LOGIN_USER]: {
    [SUCCESS]: (draft, { data }) => {
      saveUserProfile(data.result_data.member);
      draft.user_Id = data.result_data.member.uuidx;
      draft.user = data.result_data.member
    },
  },
  [Actions.SIGN_UP]: {
    [SUCCESS]: (draft, { data }) => {
      saveUserKey(data.result_data.token);
      draft.is_login = true;
      draft.token = data.result_data.token;
    },
  },
}