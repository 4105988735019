// 공통 함수
import * as CS_TYPE from './constants'
import moment from "moment/moment";

export function setOrderData (org_data, order) {
  let data = [...org_data];
  if (order === CS_TYPE.CS_SORT_BY_CL  || order ===  CS_TYPE.CS_SORT_BY_NEW) {
    data = _.map(data, item => {
      const copy_item = {...item};
      if (item.status === 'ING') {
        copy_item.sort = 1;
      } else if (item.status === 'Y') {
        copy_item.sort = 2;
      } else {
        copy_item.sort = 3;
      }
      return copy_item
    });
    data = _.orderBy(data, 'sort', 'asc');
  }

  if (!isLogin()) {

    const local_bookmarks = getLocalBookmarks();
    data = _.map(data, master => {
      const copy_master = {...master};
      const find = _.find(local_bookmarks, {csidx : master.csidx});
      if (find) {
        copy_master.bookmark = find.bookmark;
      }
      return copy_master
    });

  }

  return [...data];
}

export function bookmarkByMaster(master) {
  const local_bookmarks = JSON.parse(window.localStorage.getItem("LOCAL_BOOKMARK_MASTER")) || [];

  if (!_.some(local_bookmarks, {csidx:master.csidx})) {
    local_bookmarks.push({
      ...master,
      bookmark: 'Y',
      bookmark_date : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    });
    const ordered_local_bookmarks = _.orderBy(local_bookmarks, 'bookmark_date', 'desc');
    window.localStorage.setItem("LOCAL_BOOKMARK_MASTER", JSON.stringify(ordered_local_bookmarks));
    return ordered_local_bookmarks
  } else {
    const removed_local_bookmarks = _.reject(local_bookmarks, {csidx:master.csidx});
    window.localStorage.setItem("LOCAL_BOOKMARK_MASTER", JSON.stringify(removed_local_bookmarks));
    return removed_local_bookmarks
  }
}

export function setAllBookmarks(masters) {
  window.localStorage.setItem("LOCAL_BOOKMARK_MASTER", JSON.stringify(masters));
}

export function getLocalBookmarks(type = 'date') {
  const local_bookmarks = JSON.parse(window.localStorage.getItem("LOCAL_BOOKMARK_MASTER")) || [];

  let ordered_data = _.orderBy(local_bookmarks, 'bookmark_date', 'desc');
  if (type === 'name') {
    ordered_data = _.orderBy(local_bookmarks, 'cs_nickname', 'asc');
  }

  return ordered_data;
}

export function visitByMaster(master) {

  const local_visits = JSON.parse(window.localStorage.getItem("LOCAL_VISIT_MASTER")) || [];
  const removed_local_bookmarks = _.reject(local_visits, {csidx:master.csidx});
  removed_local_bookmarks.push({
    ...master,
    visit_date : moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  });
  const ordered_local_visits = _.orderBy(removed_local_bookmarks, 'visit_date', 'desc');
  window.localStorage.setItem("LOCAL_VISIT_MASTER", JSON.stringify(ordered_local_visits));
  return ordered_local_visits;
}

export function deleteVisitByMaster(master) {

  const local_visits = JSON.parse(window.localStorage.getItem("LOCAL_VISIT_MASTER")) || [];
  const removed_local_visits = _.reject(local_visits, {csidx:master.csidx});

  window.localStorage.setItem("LOCAL_VISIT_MASTER", JSON.stringify(removed_local_visits));
  return removed_local_visits;
}

export function getLocalVisits() {
  const local_visits = JSON.parse(window.localStorage.getItem("LOCAL_VISIT_MASTER")) || [];
  return _.orderBy(local_visits, 'visit_date', 'desc');
}

export function setMypageTab(tab) {
  window.localStorage.setItem("LOCAL_MY_PAGE_TAB", tab);
}

export function getMypageTab() {
  return window.localStorage.getItem("LOCAL_MY_PAGE_TAB") || 0;
}

export function setShopTab(tab) {
  window.localStorage.setItem("LOCAL_SHOP_TAB", tab);
}

export function getShopTab() {
  return window.localStorage.getItem("LOCAL_SHOP_TAB") || 0;
}

export function setDevice() {
  window.localStorage.setItem("LOCAL_DEVICE", 'TRUE');
}

export function getDevice() {
  return window.localStorage.getItem("LOCAL_DEVICE") === 'TRUE';
}

export function setDeviceInfo(info) {
  window.localStorage.setItem("LOCAL_DEVICE_INFO", JSON.stringify(info));
}

export function getDeviceInfo() {
  return JSON.parse(window.localStorage.getItem("LOCAL_DEVICE_INFO") || null);
}

export function setAppleLogin(info) {
  window.localStorage.setItem("LOCAL_IS_APPLE_LOGIN", 'TRUE');
  window.localStorage.setItem("LOCAL_APPLE_INFO", JSON.stringify(info));
}

export function getAppleLoginInfo() {
  return JSON.parse(window.localStorage.getItem("LOCAL_APPLE_INFO") || null);
}

export function getIsAppleLogin() {
  return window.localStorage.getItem("LOCAL_IS_APPLE_LOGIN") === 'TRUE';
}
/**
 * @return {string}
 */
export function addComma(num) {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
}

export function saveUserKey(key) {
  window.localStorage.setItem("LOCAL_USER_KEY", key);
  window.localStorage.setItem("IS_LOGIN", 'TRUE');
}

export function getUserKey() {
  return window.localStorage.getItem("LOCAL_USER_KEY") || null;
}

export function saveUserProfile(user) {
  window.localStorage.setItem("LOCAL_LOGIN_USER_UUIDX", user.uuidx);
  window.localStorage.setItem("LOCAL_LOGIN_USER", JSON.stringify(user));
}

export function getUserProfile() {
  return JSON.parse(window.localStorage.getItem("LOCAL_LOGIN_USER")) || null;
}

export function getQueryUuidx() {
  return {uuidx : window.localStorage.getItem("LOCAL_LOGIN_USER_UUIDX") || null};
}

export function isLogin() {
  return window.localStorage.getItem("IS_LOGIN") === 'TRUE';
}

export function logout() {
  window.localStorage.clear();
}

export function safeFormatToOneDecimalPlace(input) {
  if (typeof input === 'number' && !isNaN(input)) {
    return input.toFixed(1);
  }
  return null;
}