import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Promise from 'promise-polyfill';
import Root from './client/Root';

if (!window.Promise) {
  window.Promise = Promise;
}

ReactDOM.render(<Root />, document.getElementById('root'));
