import { makeGetRequest, makePostRequest } from '../api';
import _ from 'lodash';
import {getQueryUuidx} from "../../constants/funtions";


export function getContents(type='popular') {
  return makeGetRequest({
    url: `/api/cs/list/${type}`,
    params: _.pickBy(getQueryUuidx())
  });
}

export function getProfile(csidx) {
  return makeGetRequest({
    url: `/api/cs/info/${csidx}`,
    params: _.pickBy(getQueryUuidx())
  });
}

export function getMastersStatus(data) {
  return makePostRequest({
    url: `/api/cs/status/list`,
    data: data,
  });
}