export const REQUEST = 'request';
export const SUCCESS = 'success';
export const FAILURE = 'failure';

const userActions = ['LOGIN', 'LOGOUT', 'LOGIN_USER', 'SIGN_UP'];

const homeActions = [
  'HOME_POPULAR',
  'HOME_RECOMMEND',

  'HOME_TAGS',

  'HOME_TAG_AE_JEONG',
  'HOME_TAG_YEON_AE',
  'HOME_TAG_JIK_JANG',
  'HOME_TAG_CHI_UP',
  'HOME_TAG_SA_UP',
  'HOME_TAG_SUM',
  'HOME_TAG_GOONG_HAB',
  'HOME_RESET',

  'HOME_MY_BOOKMARK',
  'HOME_ADD_BOOKMARK',
  'HOME_DELETE_BOOKMARK',

  'HOME_MY_RECENTLY',
  'HOME_ADD_RECENTLY',
  'HOME_DELETE_RECENTLY',

  'HOME_MASTER_LIST',
  'HOME_MASTER_LIST_INIT',
];

const bannerActions = ['HOME_BANNER'];

const masterActions = [
  'MASTER_PROFILE',
  'MASTER_PROFILE_INIT',
  'MASTER_REVIEWS',
  'MASTER_REVIEWS_INIT',
  'MASTER_ADD_LIKE_REVIEW',
  'MASTER_DEL_LIKE_REVIEW',
];

const moreActions = [
  'NOTICE',
  'FAQ',
  'APP_INFO',
  'PAYMENTS',
  'APP_TERM',
  'APP_PRIVACY',
  'APP_CANCEL',
];

const myActions = [
  'MY_COUNSEL',
  'MY_REVIEW',
  'MY_COIN_HISTORY',
  'MY_WRITABLE_REVIEW',
  'MY_WRITABLE_REVIEW_STATUS',
  'MY_ADD_REVIEW',
  'MY_MODIFY_REVIEW',
  'MY_ADD_REVIEW_INIT',
];

export default [
  ...homeActions,
  ...bannerActions,
  ...userActions,
  ...masterActions,
  ...moreActions,
  ...myActions,
].reduce((a, c) => {
  a[c] = c;
  return a;
}, {});
