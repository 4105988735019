import { makeGetRequest } from '../api';
import {CS_SORT_BY_CL} from "../../constants/constants";
import {getQueryUuidx} from "../../constants/funtions";
import _ from "lodash";

export function getSearchByText(text, sort = CS_SORT_BY_CL) {
  const query = { keyword : text, ..._.pickBy(getQueryUuidx())};
  return makeGetRequest({
    url: `/api/search/list/${sort}`,
    params: query
  });
}