import Actions, { SUCCESS } from '../../actions';
import { isLogin, setOrderData } from '../../../../constants/funtions';

export const initialState = {
  masters: {
    masterManagement: {},

    home_popular: [[], []],
    home_recommend: [[], []],

    home_tag_ae_jeong: [],
    home_tag_yeon_ae: [],
    home_tag_jik_jang: [],
    home_tag_chi_up: [],
    home_tag_sa_up: [],
    home_tag_sum: [],
    home_tag_goong_hab: [],

    master_list: [],
    my_bookmark_list: [],
    my_recently_list: [],
  },
  actionStatus: {},
};

const ordered = (list) => {
  return setOrderData(list, 'cl');
};

const setManageMaster = (list, is_splice = false) => {
  let masterManagement = {};
  const masters = ordered(list);
  let map_master = [];
  _.map(masters, (master) => {
    masterManagement[master.csidx] = master;
    map_master.push({ csidx: master.csidx });
  });

  let final_master = [...map_master];
  if (is_splice) {
    final_master = [[...map_master].splice(0, 4), [...map_master].splice(4, 4)];
  }

  return { masterManagement: masterManagement, masters: [...final_master] };
};

export default {
  [Actions.HOME_POPULAR]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_popular: _masters.masters,
      };
    },
  },
  [Actions.HOME_RECOMMEND]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_recommend: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_AE_JEONG]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_ae_jeong: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_YEON_AE]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_yeon_ae: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_JIK_JANG]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_jik_jang: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_CHI_UP]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_chi_up: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_SA_UP]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_sa_up: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_SUM]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_sum: _masters.masters,
      };
    },
  },
  [Actions.HOME_TAG_GOONG_HAB]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list, true);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        home_tag_goong_hab: _masters.masters,
      };
    },
  },

  [Actions.HOME_MY_BOOKMARK]: {
    [SUCCESS]: (draft, { data }) => {
      if (!isLogin()) {
        draft.masters.my_bookmark_list = data.result_data.list;
      } else {
        const _masters = setManageMaster(data.result_data.list);
        draft.masters = {
          ...draft.masters,
          masterManagement: {
            ...draft.masters.masterManagement,
            ..._masters.masterManagement,
          },
          my_bookmark_list: _masters.masters,
        };
      }
    },
  },
  [Actions.HOME_ADD_BOOKMARK]: {
    [SUCCESS]: (draft, { data }) => {
      const new_master = {
        ...draft.masters.masterManagement[data.action.csidx],
      };
      new_master.bookmark = 'Y';
      draft.masters.masterManagement[data.action.csidx] = { ...new_master };
      if (!isLogin()) {
        draft.masters.my_bookmark_list = [...data.result_data];
      } else {
        draft.masters.my_bookmark_list = [
          { csidx: new_master.csidx },
          ...draft.masters.my_bookmark_list,
        ];
      }
    },
  },
  [Actions.HOME_DELETE_BOOKMARK]: {
    [SUCCESS]: (draft, { data }) => {
      const new_master = {
        ...draft.masters.masterManagement[data.action.csidx],
      };
      new_master.bookmark = 'N';
      draft.masters.masterManagement[data.action.csidx] = new_master;

      if (!isLogin()) {
        draft.masters.my_bookmark_list = [...data.result_data];
      } else {
        draft.masters.my_bookmark_list = _.reject(
          [...draft.masters.my_bookmark_list],
          { csidx: new_master.csidx }
        );
      }
    },
  },

  [Actions.HOME_MY_RECENTLY]: {
    [SUCCESS]: (draft, { data }) => {
      draft.masters.my_recently_list = _.map(data.result_data.list, (item) => {
        if (isLogin()) {
          return { csidx: item.csidx };
        } else {
          return item;
        }
      });
    },
  },
  [Actions.HOME_ADD_RECENTLY]: {
    [SUCCESS]: (draft, { data }) => {
      if (!isLogin()) {
        draft.masters.my_recently_list = [...data.result_data];
      } else {
        draft.masters.my_recently_list = [
          { csidx: data.action.csidx },
          ...draft.masters.my_recently_list,
        ];
      }
    },
  },
  [Actions.HOME_DELETE_RECENTLY]: {
    [SUCCESS]: (draft, { data }) => {
      if (!isLogin()) {
        draft.masters.my_recently_list = [...data.result_data];
      } else {
        draft.masters.my_recently_list = _.reject(
          [...draft.masters.my_recently_list],
          { csidx: data.action.csidx }
        );
      }
    },
  },

  [Actions.HOME_MASTER_LIST]: {
    [SUCCESS]: (draft, { data }) => {
      const _masters = setManageMaster(data.result_data.list);
      draft.masters = {
        ...draft.masters,
        masterManagement: {
          ...draft.masters.masterManagement,
          ..._masters.masterManagement,
        },
        master_list: _masters.masters,
      };
    },
  },
  [Actions.HOME_MASTER_LIST_INIT]: {
    [SUCCESS]: (draft, { data }) => {
      draft.masters.master_list = [];
    },
  },

  [Actions.HOME_RESET]: {
    [SUCCESS]: (draft, { data }) => {
      draft.masters = {
        masterManagement: {},

        home_popular: [[], []],
        home_recommend: [[], []],

        home_tag_ae_jeong: [],
        home_tag_yeon_ae: [],
        home_tag_jik_jang: [],
        home_tag_chi_up: [],
        home_tag_sa_up: [],
        home_tag_sum: [],
        home_tag_goong_hab: [],

        master_list: [],
        my_bookmark_list: [],
        my_recently_list: [],
      };
    },
  },
};
