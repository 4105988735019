import Actions from '../../actions'
import {BANNER_TYPE_MAIN} from "../../../../constants/constants";
import {getBanners} from "../../../../services/apis/banners";
import {getTags} from "../../../../services/apis/tags";

const callBanners = function*(action) {
  return yield getBanners(BANNER_TYPE_MAIN, action.data);
};

const callTags = function*(action) {
  return yield getTags(action.data);
};

export default [
  [Actions.HOME_BANNER, callBanners],
  [Actions.HOME_TAGS, callTags],
]