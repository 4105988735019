import Actions from '../../actions';
import { getTagsByTagId } from '../../../../services/apis/tags';
import {
  getContents,
  getMastersStatus,
} from '../../../../services/apis/contents';
import * as CS_TYPE from '../../../../constants/constants';
import {
  bookmarkByMaster,
  deleteVisitByMaster,
  getLocalBookmarks,
  getLocalVisits,
  isLogin,
  setOrderData,
  visitByMaster,
} from '../../../../constants/funtions';
import {
  addBookmark,
  addRecently,
  deleteBookmark,
  deleteRecently,
  getMyBookmark,
  getMyRecently,
} from '../../../../services/apis/my';
import { getSearchByText } from '../../../../services/apis/search';
import {
  CS_TYPE_RECOMMEND,
  CS_TYPE_POPULARS,
  CS_TYPE_NEW,
} from '../../../../constants/constants';

const getRecommendContents = function* (action) {
  return yield getContents(CS_TYPE_NEW, action.data);
};

const getPopularContents = function* (action) {
  return yield getContents(CS_TYPE_POPULARS, action.data);
};

const getTagsAejeongContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_AE_JEONG,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getTagsYeonaeContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_YEON_AE,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getTagsJikjangContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_JIK_JANG,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getTagsChiupContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_CHI_UP,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getTagsSaupContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_SA_UP,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getTagsSumContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_SUM,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getTagsGoonhabContents = function* (action) {
  return yield getTagsByTagId(
    CS_TYPE.CS_TAG_SINGLE_GOONG_HAB,
    CS_TYPE_RECOMMEND,
    action.data
  );
};

const getMasters = function* (action) {
  if (action.data.apiType === 'TAG') {
    let response = yield getTagsByTagId(
      action.data.tagId,
      action.data.order,
      action.data
    );
    response.data.result_data = {
      ...response.data.result_data,
      list: setOrderData(response.data.result_data.list, action.data.order),
    };
    return response;
  } else if (action.data.apiType === 'SEARCH') {
    let response = yield getSearchByText(
      action.data.text,
      action.data.order,
      action.data
    );

    response.data.result_data = {
      ...response.data.result_data,
      list: setOrderData(response.data.result_data.list, action.data.order),
    };
    return response;
  } else {
    let response = yield getContents(action.data.apiType, action.data);
    response.data.result_data = {
      ...response.data.result_data,
      list: setOrderData(response.data.result_data.list, action.data.order),
    };
    return response;
  }
};

const getInitMasters = function* (action) {
  return { data: [] };
};

const getResetHome = function* (action) {
  return { data: {} };
};

const myAddBookmark = function* (action) {
  if (isLogin()) {
    return yield addBookmark(action.data.csidx, action.data);
  } else {
    return {
      data: {
        result_type: 'local',
        result_code: 200,
        result_data: bookmarkByMaster({
          csidx: action.data.csidx,
          cs_nickname: action.data.master.cs_nickname,
        }),
      },
    };
  }
};

const myDeleteBookmark = function* (action) {
  if (isLogin()) {
    return yield deleteBookmark(action.data.csidx, action.data);
  } else {
    return {
      data: {
        result_type: 'local',
        result_code: 200,
        result_data: bookmarkByMaster({ csidx: action.data.csidx }),
      },
    };
  }
};

const myBookmark = function* (action) {
  if (isLogin()) {
    return yield getMyBookmark(action.data.order, action.data);
  } else {
    const local_bookmark = getLocalBookmarks(action.data.order);

    const map_idx = _.map(local_bookmark, 'csidx');
    let master_status = { data: { result_data: { list: [] } } };
    if (map_idx.length > 0) {
      master_status = yield getMastersStatus({ csidxs: map_idx });
    }

    const merge_bookmark = _.map(local_bookmark, (mark) => {
      const find = _.find(master_status.data.result_data.list, {
        csidx: mark.csidx,
      });
      return { ...mark, ...find };
    });

    return {
      data: {
        result_type: 'local',
        result_code: 200,
        result_data: { list: merge_bookmark },
      },
    };
  }
};

const myRecently = function* (action) {
  if (isLogin()) {
    return yield getMyRecently(action.data);
  } else {
    const local_visits = getLocalVisits();

    const map_idx = _.map(local_visits, 'csidx');
    let master_status = { data: { result_data: { list: [] } } };
    if (map_idx.length > 0) {
      master_status = yield getMastersStatus({ csidxs: map_idx });
    }

    const merge_visits = _.map(local_visits, (visit) => {
      const find = _.find(master_status.data.result_data.list, {
        csidx: visit.csidx,
      });
      return { ...visit, ...find };
    });

    return {
      data: {
        result_type: 'local',
        result_code: 200,
        result_data: { list: merge_visits },
      },
    };
  }
};

const myAddRecently = function* (action) {
  if (isLogin()) {
    return yield addRecently(action.data.csidx, action.data);
  } else {
    return {
      data: {
        result_type: 'local',
        result_code: 200,
        result_data: visitByMaster({ csidx: action.data.csidx }),
      },
    };
  }
};

const myDeleteRecently = function* (action) {
  if (isLogin()) {
    return yield deleteRecently(action.data.csidx, action.data);
  } else {
    return {
      data: {
        result_type: 'local',
        result_code: 200,
        result_data: deleteVisitByMaster({ csidx: action.data.csidx }),
      },
    };
  }
};

export default [
  [Actions.HOME_POPULAR, getPopularContents],
  [Actions.HOME_RECOMMEND, getRecommendContents],

  [Actions.HOME_TAG_AE_JEONG, getTagsAejeongContents],
  [Actions.HOME_TAG_YEON_AE, getTagsYeonaeContents],
  [Actions.HOME_TAG_JIK_JANG, getTagsJikjangContents],
  [Actions.HOME_TAG_CHI_UP, getTagsChiupContents],
  [Actions.HOME_TAG_SUM, getTagsSumContents],
  [Actions.HOME_TAG_SA_UP, getTagsSaupContents],

  [Actions.HOME_TAG_GOONG_HAB, getTagsGoonhabContents],

  [Actions.HOME_MY_BOOKMARK, myBookmark],
  [Actions.HOME_ADD_BOOKMARK, myAddBookmark],
  [Actions.HOME_DELETE_BOOKMARK, myDeleteBookmark],

  [Actions.HOME_MY_RECENTLY, myRecently],
  [Actions.HOME_ADD_RECENTLY, myAddRecently],
  [Actions.HOME_DELETE_RECENTLY, myDeleteRecently],

  [Actions.HOME_MASTER_LIST, getMasters],
  [Actions.HOME_MASTER_LIST_INIT, getInitMasters],

  [Actions.HOME_RESET, getResetHome],
];
