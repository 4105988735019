import Actions from '../../actions'
import {getProfile} from "../../../../services/apis/contents";
import {addLikeReview, delLikeReview, getReviewsByCsId} from "../../../../services/apis/reviews";

const getMaster = function*(action) {
  const res = yield getProfile(action.data.csidx, action.data);
  const cs_photos = res.data.result_data.master.cs_photos;
  //todo(toryyr) : -.- (일단은 파싱)
  _.set(res, ['data', 'result_data', 'master', 'cs_photos'], cs_photos.substr(1, cs_photos.length-2).split(','));
  return yield res;
};

const getMasterReviews = function*(action) {
  return yield getReviewsByCsId(action.data.csidx, action.data.offset, action.data.limit, action.data.order, action.data);
};

const getInitMaster = function*(action) {
  return {data : {}};
};

const getInitReviews = function*(action) {
  return {data : {}};
};
const likeReview = function*(action) {
  return yield addLikeReview(action.data.rvidx, action.data);
};

const unlikeReview = function*(action) {
  return yield delLikeReview(action.data.rvidx, action.data);
};

export default [
  [Actions.MASTER_PROFILE, getMaster],
  [Actions.MASTER_PROFILE_INIT, getInitMaster],
  [Actions.MASTER_REVIEWS, getMasterReviews],
  [Actions.MASTER_REVIEWS_INIT, getInitReviews],
  [Actions.MASTER_ADD_LIKE_REVIEW, likeReview],
  [Actions.MASTER_DEL_LIKE_REVIEW, unlikeReview],
]