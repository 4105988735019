import Actions, {SUCCESS} from '../../actions';

export const initialState = {
  more: {
    notice:[],
    faq:[],
    app_info:{},
    payments:[],
    app_term:{},
    app_privacy:{},
    app_cancel:{}
  },
  actionStatus: {},
};

export default {
  [Actions.NOTICE]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.notice = data.result_data.list;
    },
  },
  [Actions.FAQ]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.faq = data.result_data.list;
    },
  },
  [Actions.APP_INFO]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.app_info = data.result_data.app;
    },
  },
  [Actions.PAYMENTS]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.payments = data.result_data.list;
    },
  },
  [Actions.APP_TERM]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.app_term = data.result_data.app;
    },
  },
  [Actions.APP_PRIVACY]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.app_privacy = data.result_data.app;
    },
  },
  [Actions.APP_CANCEL]: {
    [SUCCESS]: (draft, { data }) => {
      draft.more.app_cancel = data.result_data.app;
    },
  },
}