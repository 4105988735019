import Actions from '../../actions'
import {getFaq} from "../../../../services/apis/faqs";
import {getNoticeList} from "../../../../services/apis/notices";
import {getAppInfo} from "../../../../services/apis/users";
import {getPayments} from "../../../../services/apis/payments";

const notice = function*(action) {
  return yield getNoticeList(action.data);
};

const faq = function*(action) {
  return yield getFaq(action.data);
};

//정보타입(version:안드버전정보,tos:이용약관,privacy:개인정보,company:회사소개,cancel:취소환불)
const appInfo = function*(action) {
  return yield getAppInfo('version', action.data);
};

const appTerm = function*(action) {
  return yield getAppInfo('tos', action.data);
};

const appPrivacy = function*(action) {
  return yield getAppInfo('privacy', action.data);
};

const appCancel = function*(action) {
  return yield getAppInfo('cancel', action.data);
};

const payments = function*(action) {
  return yield getPayments(action.data);
};

export default [
  [Actions.NOTICE, notice],
  [Actions.FAQ, faq],
  [Actions.APP_INFO, appInfo],
  [Actions.PAYMENTS, payments],
  [Actions.APP_TERM, appTerm],
  [Actions.APP_PRIVACY, appPrivacy],
  [Actions.APP_CANCEL, appCancel],
]