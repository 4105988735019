import { makeGetRequest, makePostRequest, makePutRequest } from '../api';
import _ from 'lodash';
import {getQueryUuidx} from "../../constants/funtions";

export function getReviewsByCsId(cs_id, offset, limit, sort) {
  const query = {
    page_size: limit,
    ordering: offset,
    ..._.pickBy(getQueryUuidx())
  };
  return makeGetRequest({
    url: `/api/review/list/${cs_id}/${sort}`,
    params: query
  });
}

export function addReview(data) {
  return makePostRequest({
    url: `/api/review/add/review`,
    data: data
  });
}

export function editReview(data) {
  return makePutRequest({
    url: `/api/review/modify/review`,
    data: data
  });
}

export function addLikeReview(rvidx, data) {
  return makePutRequest({
    url: `/api/review/add/like/${rvidx}`,
    data: data
  });
}

export function delLikeReview(rvidx, data) {
  return makePutRequest({
    url: `/api/review/del/like/${rvidx}`,
    data: data
  });
}

export function addReportReview(rvidx, data) {
  return makePutRequest({
    url: `/api/review/add/report/${rvidx}`,
    data: data
  });
}