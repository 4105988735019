import Actions, {SUCCESS} from '../../actions';

export const initialState = {
  counsel:[],
  review:[],
  coin_history:[],
  writable_review:[],
  writable_review_status:{},
  actionStatus: {},
  add_review: {result_code:0}
};

export default {
  [Actions.MY_COUNSEL]: {
    [SUCCESS]: (draft, { data }) => {
      draft.counsel = data.result_data.list;
    },
  },
  [Actions.MY_REVIEW]: {
    [SUCCESS]: (draft, { data }) => {
      draft.review = data.result_data.list;
    },
  },

  [Actions.MY_COIN_HISTORY]: {
    [SUCCESS]: (draft, { data }) => {
      draft.coin_history = data.result_data.list;
    },
  },

  [Actions.MY_WRITABLE_REVIEW]: {
    [SUCCESS]: (draft, { data }) => {
      draft.writable_review = data.result_data.list;
    },
  },
  [Actions.MY_WRITABLE_REVIEW_STATUS]: {
    [SUCCESS]: (draft, { data }) => {
      draft.writable_review_status = data.result_data;
    },
  },

  [Actions.MY_ADD_REVIEW]: {
    [SUCCESS]: (draft, { data }) => {
      draft.add_review = data;
    },
  },

  [Actions.MY_MODIFY_REVIEW]: {
    [SUCCESS]: (draft, { data }) => {
      draft.add_review = data;
    },
  },

  [Actions.MY_ADD_REVIEW_INIT]: {
    [SUCCESS]: (draft, { data }) => {
      draft.add_review = {result_code:0}
    },
  },
}