import Actions, {FAILURE, SUCCESS} from '../../actions';

export const initialState = {
  profile:{tag:[], cs_photos:[], link:[]},
  reviews:{list:[], rating_info:{}, average: 0, total_count: 0},
  actionStatus: {},
  like_review: {result_code:0}
};

export default {
  [Actions.MASTER_PROFILE]: {
    [SUCCESS]: (draft, { data }) => {
      draft.profile = data.result_data.master;
    },
  },
  [Actions.MASTER_PROFILE_INIT]: {
    [SUCCESS]: (draft, { data }) => {
      draft.profile = {tag:[], cs_photos:[]};
      draft.reviews = {list:[], rating_info:{}, average: 0, total_count: 0}
    },
  },

  [Actions.MASTER_REVIEWS_INIT]: {
    [SUCCESS]: (draft, { data }) => {
      draft.reviews = {list:[], rating_info:{}, average: 0, total_count: 0}
    },
  },

  [Actions.MASTER_REVIEWS]: {
    [SUCCESS]: (draft, { data }) => {
      draft.reviews.average = data.result_data.average;
      draft.reviews.total_count = data.result_data.total_count;
      draft.reviews.rating_info = data.result_data.rating_info;
      if (draft.profile.csidx === parseInt(data.action.csidx)) {
        draft.reviews.list = [...draft.reviews.list, ...data.result_data.list];
      } else {
        draft.reviews.list = [...data.result_data.list];
      }

    },
    [FAILURE]: (draft, { error }) => {
      draft.reviews = {list:[], rating_info:{}, average: 0, total_count: 0}
    },
  },

  [Actions.MASTER_ADD_LIKE_REVIEW]: {
    [SUCCESS]: (draft, { data }) => {
      const final_reviews = _.map([...draft.reviews.list], rev => {
        if (rev.rvidx === data.action.rvidx) {
          return {
            ...rev,
            rv_like_yn : 'Y',
            rv_like: rev.rv_like + 1
          }
        }
        return rev;
      });

      draft.reviews.list = [...final_reviews];
      draft.like_review = data;
    },
  },

  [Actions.MASTER_DEL_LIKE_REVIEW]: {
    [SUCCESS]: (draft, { data }) => {
      const final_reviews = _.map([...draft.reviews.list], rev => {
        if (rev.rvidx === data.action.rvidx) {
          return {
            ...rev,
            rv_like_yn : 'N',
            rv_like: rev.rv_like - 1
          }
        }
        return rev;
      });
      draft.reviews.list = [...final_reviews];
      draft.like_review = data;
    },
  },
}